@import "../../../../styles/utils/_colors.scss";
@import "../../../../styles/utils/_variables.scss";
@import "../../../../styles/base/_reset.scss";
@import "../../../../styles/base/_typography.scss";

.chatbotsContent {
  color: $dark;

  h1.title {
    margin-bottom: 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $dark;
    margin-top: 3em;
  }

  .chatbotToggle {
    button {
      width: 64px;
      height: 41px;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: $white;
    }

    button:nth-child(1) {
      margin-right: 0.5em;
    }

    .on {
      background: $primary-blue;
    }

    .off {
      background: #e0e0e0;
    }
  }
}

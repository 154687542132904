@import "../../../../styles/utils/_colors.scss";
@import "../../../../styles/utils/_variables.scss";
@import "../../../../styles/base/_reset.scss";
@import "../../../../styles/base/_typography.scss";

.accountContent {
  color: $dark;
  padding: 0px 20px;
  hr {
    margin: 40px 20px;
    border: 1px solid rgb(218, 218, 218);
  }

  h1.title {
    margin-bottom: 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: rgba(22, 22, 22, 0.7);
  }

  .saveWidgetsButton {
    padding: 0 3em;
    justify-content: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    background: $primary-blue;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;
  }
  button {
    padding: 0 3em;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    padding: 0.6em 2em;
    color: $white;
    height: 3em;
    max-width: 250px;
    background-color: $primary-blue;
  }
  button:hover{
    background-color: $hover-blue;
  }
  .showPass {
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    padding: 0px;
    margin: 0px 15px 0px 15px;

    &:hover {
      background-color: $white;
    }

    $visibiltyIconWidth: 2px;

    .visibilityEyeShape {
      position: relative;
      margin: 0 auto;
      width: 15px;
      height: 15px;
      border: $visibiltyIconWidth solid #5c5c5c;
      border-radius: 70% 3px;
      transform: rotate(45deg);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2em;

      .visibilityEyeBall {
        position: absolute;
        width: 70%;
        height: 70%;
        border-radius: 50%;
        border: $visibiltyIconWidth solid #5c5c5c;
      }
      .visibilityHide {
        position: absolute;
        top: -25%;
        height: 150%;
        width: $visibiltyIconWidth;
        background: #5c5c5c;
        box-shadow: $visibiltyIconWidth * 0.3 0px 0px 0px white;
      }
    }
  }

  .placeholder {
    display: block;
    width: 150px;
    height: 150px;
    background-color: $white;
    border-radius: 100%;
    margin-bottom: 20px;
    box-shadow: 0px 0px 146px rgba(136, 165, 191, 0.38);
    img {
      padding: 6px;
    }
  }
  .accountRow {
    display: flex;
    flex-direction: row;
  }
  .accountRow div {
    &:nth-child(0) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  .widgetSettingGroup {
    display: flex;
    flex-direction: column;

    span {
      padding: 10px 0px;
    }
    label {
      min-width: 160px;
      display: inline-block;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: rgba(55, 76, 131, 0.7);
    }
    input,
    input[type="password"],
    input[type="text"] {
      background: $white;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
      border-radius: 40px;
      height: 3em;
      min-width: 300px;
      -webkit-appearance: none;
      border: none;
      text-indent: 20px;
      margin-left: 10px;
      outline: none;
      padding: initial;
      font: unset;

      &::placeholder {
        font-size: 14px;
        color: rgb(110, 110, 110);
      }
      input[type="password" i] {
        padding: 0px !important;
        margin: 0;
      }
    }
  }
}

@media only screen and (min-width:768px) {
    .saveWidgetsButton  {
      align-self: flex-end;
    } 
    .accountRow div {
      &:nth-child(0) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      &:nth-child(2) {
        margin-left: 60px;
      }
    }
}

@media only screen and (max-width:768px) {
  .accountRow{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    
    }
    .accountRow div:nth-child(1){
      margin:0 auto;
      padding-bottom: 30px;
    }
    .saveWidgetsButton  {
      align-self: center;
    }
    .accountRow div {
      &:nth-child(0) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      &:nth-child(2) {
        margin-left: 10px;
      }
    }

    
  }

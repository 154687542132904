.appBody {
    width: 100%;
    height: 100%;
    position: relative;
    background: #eef2fa;
    overflow: hidden;

    .bodyVector1 {
        pointer-events: none;
        z-index: 0;
        position: absolute;
        left: 21.06%;
        right: -40.25%;
        top: 1.89%;
        bottom: -74.56%;

        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 185, 0, 0.75) 0%, rgba(196, 196, 196, 0) 100%);
        opacity: 0.1;
    }

    .bodyVector2 {
        pointer-events: none;
        z-index: 0;
        position: absolute;
        left: -51.25%;
        right: 21.06%;
        top: -75.89%;
        bottom: -12.56%;

        background: radial-gradient(50% 50% at 50% 50%, rgba(216, 59, 1, 0.75) 0%, rgba(196, 196, 196, 0) 100%);
        opacity: 0.2;
    }

    .bodyVector3 {
        pointer-events: none;
        z-index: 0;
        position: absolute;
        left: -13.25%;
        right: -66%;
        top: -93.33%;
        bottom: -66.22%;
        background: radial-gradient(50% 50% at 50% 50%, #107c10 0%, rgba(196, 196, 196, 0) 100%);
        opacity: 0.1;
    }

    .bodyVector4 {
        pointer-events: none;
        z-index: 0;

        position: absolute;
        left: -49.88%;
        right: 9.06%;
        top: -35.89%;
        bottom: -68.11%;

        background: radial-gradient(50% 50% at 50% 50%, #0078d4 0%, rgba(196, 196, 196, 0) 100%);
        opacity: 0.15;
    }
}

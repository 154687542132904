.editNodeBody {
    position: absolute;
    height: 100vh;
    width: 30%;
    top: 0;
    left: 0;
    z-index: 4;
    background: linear-gradient(167.09deg, rgba(176, 220, 215, 0.268) 3.21%, rgba(193, 203, 230, 0.2546) 100%);
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 48%);
    border-radius: 20px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #626262;

    padding: 2em;

    button {
        width: 30%;
        margin-top: 0.6em;
        padding: 0.5em;
        margin-left: 0.3em;
        display: block;
        background: #393e85;
        color: #ffffff;
        border-radius: 20px;
        box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 48%);
    }

    span {
        display: block;
    }

    .contentBox {
        margin-top: 1em;
        margin-bottom: 1em;
        background: #ffffff;
        box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
        height: 167px;
    }

    .messageContent {
        border-radius: 20px;
        height: 100%;
        width: 100%;
        padding: 1em;
        border: none;
    }

    .messageQuickReply {
        border-radius: 40px;
    }

    .addConnection {
        border: 1px dashed rgba(57, 62, 133, 0.5);
        box-sizing: border-box;
        border-radius: 40px;
    }
}

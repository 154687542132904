$headerBGColor: #393e85;
$nodeBGColor: #ffffff;
$whiteBoxShadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
$contentTextColor: #fff;

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@500&display=swap");

@mixin nodeFont {
  font-family: Mukta;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: $contentTextColor;
}

@mixin quickReplyFont {
  font-family: Mukta;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #696c8f;
}

@mixin messageBlockFont {
  font-family: Mukta;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #696c8f;
}

@mixin nodeHeaderFont {
  font-family: Mukta;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
}

@mixin blockBodyStyle {
  background: $nodeBGColor;
  box-shadow: $whiteBoxShadow;
  border-radius: 20px;
}

.addNode {
  @include blockBodyStyle;
  @include nodeFont;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 4;
  ul {
    list-style: none;
    padding: 0;
    margin-left: 5px;
    color: black;
    padding: 1.2em;

    li {
      padding: 5px;
    }
  }
}

.quickReply {
  @include quickReplyFont;
  background: $nodeBGColor;
  box-shadow: $whiteBoxShadow;
  border-radius: 40px;
  padding: 5px;
  margin: auto;
  margin-bottom: 14px;
  width: 90%;
  cursor: grab;
  position: relative;
  svg {
    margin-left: 5px;
  }
  span {
    margin-left: 5px;
  }

  div {
    cursor: pointer;
    width: 8px;
    height: 8px;
    border-color: #393e85;
    border-width: 2px;
    background: #d7e2ef;
  }
}

.nodeHeader {
  @include nodeHeaderFont;
  background: $headerBGColor;
  box-shadow: $whiteBoxShadow;
  border-radius: 20px 20px 0px 0px;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 15px;
}

.node {
  background: $nodeBGColor;
  box-shadow: $whiteBoxShadow;
  border-radius: 20px;
}

.nodeContentWrapper {
  padding: 10px;
  margin-top: 33px;
  margin-bottom: 33px;
  background: #ffffff;
}

.connectionCircle {
  background: #d7e2ef;
  border: 1px solid $headerBGColor;
  box-sizing: border-box;
}

.textBlock {
  @include messageBlockFont;
  background: $nodeBGColor;
  box-shadow: $whiteBoxShadow;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
}

.editNode {
  position: absolute;
  height: 100vh;
  width: 30%;
  top: 0;
  left: 0;
  z-index: 4;
  background: linear-gradient(167.09deg, rgba(176, 220, 215, 0.268) 3.21%, rgba(193, 203, 230, 0.2546) 100%);
  box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 48%);
  border-radius: 20px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #626262;
}

.btn {
  @include messageBlockFont;
  background: $headerBGColor;
  color: $nodeBGColor;
  border-radius: 20px;
  box-shadow: $whiteBoxShadow;
  position: absolute;
  position: absolute;
  top: 2%;
  left: 88%;
  padding: 13px;
  z-index: 5;
}

.btnRestore {
  @include messageBlockFont;
  background: $headerBGColor;
  color: $nodeBGColor;
  border-radius: 20px;
  box-shadow: $whiteBoxShadow;
  position: absolute;
  position: absolute;
  top: 2%;
  left: 83%;
  padding: 13px;
  z-index: 5;
}

.builderHeader {
  background: #ffffff;
  box-shadow: 0px 8px 15px rgba(147, 147, 147, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 3;
  padding-left: 76px;
  padding-top: 14px;
  padding-bottom: 14px;
  position: relative;

  span {
    color: #696c8f;
    font-weight: bold;
    font-size: 16px;
  }

  svg {
    margin-left: 14px;
    margin-right: 14px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');

.conversations-body {
    position: relative;
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
    border-radius: 16px;
    margin: 10vh;
    margin-top: 5vh;
    display: grid;
    grid-template-columns: minmax(150px, 25%) 1fr;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 28px;
    color: rgba(55, 83, 111, 0.4);
    height: 85vh;
    z-index: 1;
}

.message-body {
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
    border-radius: 16px;
    max-width: 65%;
    margin: 1.4vh;
    text-align: center;
    padding: 10px;
    color: black;
}

.message-request {
    left: -6vw;
    position: relative;
    display: inline-block;
}

.message-response {
    left: 6vw;
    position: relative;
    display: inline-block;
    background: #eff2fa;
}

.conversation-div {
    display: grid;
    grid-template-rows: 1fr 7fr auto;
    overflow-y: auto;
}

.conversations-list {
    display: flex;
    flex-direction: column;
    margin-top: 0.1vh;
    margin-bottom: 0.1vh;
    background: #ffffff;
    /* shadow white */
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 16px;
    padding: 10%;
    padding-top: 10vh;
}

.conversation-item {
    display: block;
    background: #ffffff;
    /* shadow white */
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;
    margin-bottom: 10px;
    padding: 10px;
}

.conversation-controls {
    display: flex;
    justify-content: center;
}

.message-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 2vh;
}

.message-controls-type {
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
    border-radius: 16px;
    height: 50px;
    width: 70%;
    margin-bottom: 20px;
    text-indent: 20px;
}

.message-controls-send {
    background: #b9cce2;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
    border-radius: 16px;
    height: 50px;
    margin-bottom: 20px;
    width: 15%;
    margin-left: 10px;
    font-weight: 700;
    color: #ffffff;
}

.conversation-ui-msg {
    display: flex;
    align-self: center;
    justify-self: center;
}

.submit-message-form {
    display: inherit;
    justify-content: inherit;
    width: 100%;
}

.submit-message-form > input {
    cursor: text;
}

.conversation-active {
    background: #eef2fa;
    box-shadow: inset -3px -3px 7px #ffffff, inset 3px 3px 7px rgb(136 165 191 / 48%);
    border-radius: 16px;
    opacity: 0.6;
    color: black;
    font-weight: 700;
}

button,
input[type='submit'],
input[type='reset'],
input[type='text'] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

/*
 * Scrollbar styles
 */

.conversation-div::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
}

.conversation-div::-webkit-scrollbar {
    width: 12px;
}

.conversation-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #eff2fa;
}

@import "../../../../styles/utils/_colors.scss";
@import "../../../../styles/utils/_variables.scss";
@import "../../../../styles/base/_reset.scss";
@import "../../../../styles/base/_typography.scss";

.tagsContent {
  color: $dark;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
  height: 100%;
  align-content: flex-start;

  // Override parent's padding because we are using Sliding from the right Modal animation
  margin-right: -3em;

  h1.title {
    margin-bottom: 1.5em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    width: 100%;
  }

  .tag {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    min-width: 120px;
    height: 2.8em;
    background: #fafafc;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;
    padding-left: 1.5em;
    padding-right: 1em;
    margin: 0em 0.9em 1em 0em;

    span:nth-child(1) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.4);
      cursor: default;
    }

    span:nth-child(2) {
      color: $primary-red;
      transform: rotate(-44.04deg);
      margin-left: 0.9em;
      cursor: pointer;

      &:hover{
        color: $hover-red;
      }

    }
  }

  .addTag {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: $light-blue;
    margin-bottom: 1em;
    align-self: center;
    cursor: pointer;
  }

  .addNewModal {
    animation: slide 0.5s forwards;
    position: absolute;
    width: 70%;
    top: 25%;
    height: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    background: $white;
    box-shadow: -4px -2px 16px #fff, 4px 2px 25px rgb(138 139 139 / 48%);
    border-radius: 15px;

    margin-left: -1em;

    button {
      background: rgba(55, 76, 131, 0.7);
      padding: 0.5em 3em;
      background: #e5ecf5;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 38%);
      border-radius: 40px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  @keyframes slide {
    0% {
      left: 80%;
    }
    100% {
      left: 15%;
    }
  }
}

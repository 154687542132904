$headerBGColor: #393e85;
$nodeBGColor: #ffffff;
$whiteBoxShadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
$contentTextColor: #696c8f;

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

@mixin nodeFont {
    font-family: Mukta;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: $contentTextColor;
}

@mixin iconStyle {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #393e85;
}

.react-flow__controls {
    box-shadow: none !important;
    top: 2%;
    left: 95% !important;
    .react-flow__controls-button {
        background: #ffffff;
        border: 1px solid #e0e8f1;
        box-sizing: border-box;
        //box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 48%);
        border-radius: 20px;
        width: 41px;
        height: 41px;
        margin-bottom: 5px;
        svg {
            @include iconStyle;
        }
    }
}

.react-flow__nodes {
    .react-flow__node {
        @include nodeFont;
        background: $nodeBGColor;
        box-shadow: $whiteBoxShadow;
        border-radius: 20px;
        border-style: none;
        padding: 0;
        width: 220px;
    }
}

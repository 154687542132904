.intentsTableBody {
    background: rgba(251, 251, 252, 0.9);
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 10px;
    padding: 3em;
    height: inherit;
    .header {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;

        .headerTitle {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
            display: inline-block;
        }

        .topicsSelector {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: right;
            text-decoration-line: underline;
            color: #989898;
            display: inline-block;
        }
    }

    .intentsTable {
        width: 100%;
        border-spacing: 0 1em;
        border-collapse: separate;
        margin-top: 2em;

        tbody {
            tr:first-child {
                text-align: center;
            }

            tr:not(:first-child) {
                margin-bottom: 2em;
                background: #ffffff;
                box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
                border-radius: 40px;

                td {
                    padding: 1em;
                    background: #ffffff;
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;

                    color: #6b6b6b;
                }

                td:last-child {
                    border-top-right-radius: 40px;
                    border-bottom-right-radius: 40px;
                }

                td:first-child {
                    border-top-left-radius: 40px;
                    border-bottom-left-radius: 40px;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #696c8f;
                }

                td:not(:first-child) {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #696c8f;
                }
            }
        }
    }
}

@import "../../../../styles/utils/_colors.scss";
@import "../../../../styles/utils/_variables.scss";
@import "../../../../styles/base/_reset.scss";
@import "../../../../styles/base/_typography.scss";

.integrationContent {
  color: $dark;

  h1.title {
    margin-bottom: 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
}

.section {
  display: flex;
  flex-flow: column;
  margin-bottom: 2.5em;

  .sectionRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
  }

  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  button {
    background: $primary-blue;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    height: 45px;
    width: 200px;
    color: #ffffff;
  }
  button.blue_hover:hover {
    background: $hover-blue;
  }


  .disengage {
    background: $primary-red;
    align-self: flex-end;
  }
  .disengage:hover {
    background: $hover-red;
  }

  .integrationInput {
    display: flex;
    flex-flow: column;
    margin-top: 1em;

    label {
      margin-left: 10px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;

      color: rgba(0, 0, 0, 0.4);
    }

    input {
      background: $white;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
      border-radius: 40px;
      height: 40px;
      width: 270px;
      margin-right: 2em;
      -webkit-appearance: none;
      border: none;
      text-indent: 20px;
    }
  }
}


@media only screen and (max-width:768px) {
  .integrationContent .sectionRow{
        flex-wrap: wrap;

        button{
          margin-top: 20px;
        }
      }
    
  }
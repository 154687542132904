$greyer: #707070;

.conversationBody {
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
    border-radius: 15px;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(150px, 25%) 1fr;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 28px;
    color: rgba(55, 83, 111, 0.4);
    height: 85vh;
    z-index: 1;
    position: relative;
}

.conversationContentContainer {
    display: grid;
    grid-template-rows: 1fr 7fr auto;
    overflow-y: auto;
}

.conversationControls {
    display: flex;
    flex-direction: column;
    margin-top: 0.1vh;
    margin-bottom: 0.1vh;
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
    border-radius: 15px 15px 0px 15px;
    padding-left: 16px;
    padding-right: 22px;
    padding-top: 3vh;
    z-index: 1;
    position: relative;
    overflow: hidden;
    transition: width 0.5s;

    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
    }
}

.conversationSelectorsContainer {
    position: relative;
    width: 100%;
    overflow-y: auto;
    padding-top: 15px;
    padding-right: 25px;
    padding-left: 10px;
    padding-bottom: 35px;

    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
        background: #c4c4c4;
        border-radius: 50px;
    }
}

.conversationSelectorTab {
    position: relative;
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 15px;
    font-family: Mukta;
    font-style: normal;
    margin-bottom: 13px;
    height: 86px;
    font-size: 18px;
    line-height: 109.42%;
    color: #868686;
    text-align: left;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    &:first-child {
        margin-top: 15px;
    }
}

.conversationControlsClosed {
    overflow: hidden;
    width: 100px;
}

.activeTab {
    font-weight: 500;
}

.tabSubText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(55, 83, 111, 0.4);
    pointer-events: none;
}

.activeConversationCircle {
    height: 9px;
    width: 9px;
    margin-bottom: 16px;
    margin-left: 12px;
    border-radius: 50%;
    background: #48d348;
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.unreadConversationCircle {
    height: 9px;
    width: 9px;
    margin-bottom: 16px;
    margin-left: 12px;
    border-radius: 50%;
    background: #2ab2eb;
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 19px;
    right: 30px;
}

.readConversationCircle {
    height: 9px;
    width: 9px;
    margin-bottom: 16px;
    margin-left: 12px;
    border-radius: 50%;
    background: #e6e6e6;
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 19px;
    right: 30px;
}

.currentConversationActions {
    display: flex;
    margin-right: 20px;
    span {
        font-family: Mukta;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #343434;
        margin-right: 20px;
    }
}

.menuDots {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 20.03px;
    justify-content: center;
    margin: auto;
    div {
        border-radius: 50%;
        background: #343434;
        width: 4.35px;
        height: 4.35px;
        margin: auto;
    }
}

.conversationHeader {
    display: flex;
    align-items: center;
    padding-left: 65px;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 8px 15px rgba(147, 147, 147, 0.1);
    height: 59px;
    /* Header Title Text */
    font-family: Mukta;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #5a5a5a;
}

.conversationFilterControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:last-child {
        display: flex;
        align-items: center;
    }

    div:first-child {
        font-family: Mukta;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: #000000;
        margin-left: 15px;
    }
}

.searchContainer {
    position: relative;
    margin-top: 25px;
}

.searchIcon {
    position: absolute;
    right: 0px;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.conversationSearchInput {
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 56%);
    border-radius: 40px;
    width: 100%;
    height: 36px;
    text-indent: 30px;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    &:focus {
        outline: none;
    }
}

.searchFilterToggle {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 25px;
    button {
        text-decoration: underline;
        cursor: pointer;
    }
}

.messagesContainer {
    background: #ffffff;
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow-y: auto;

    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
        background: #c4c4c4;
        border-radius: 50px;
    }
}

.messageRequest {
    left: -10vw;
    position: relative;
    display: inline-block;
    background: #f0f0f0;
    border-radius: 15px 15px 0px 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    padding-left: 19px;
    padding-right: 19px;
    color: #0a0a0a;
}

.messageResponse {
    left: 10vw;
    position: relative;
    display: inline-block;
    background: #2e2e2e;
    border-radius: 15px 15px 0px 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    padding-left: 19px;
    padding-right: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
}

.messageBody {
    border-radius: 15px 15px 0px 15px;
    word-wrap: break-word;
    max-width: 60ch;
    margin: 1.4vh;
    text-align: center;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.message-request {
    left: -6vw;
    position: relative;
    display: inline-block;
}

.message-response {
    left: 6vw;
    position: relative;
    display: inline-block;
    background: #eff2fa;
}

.conversation-controls {
    display: flex;
    justify-content: center;
}

.widgetForm {
    width: 100%;
    height: 118px;
    display: flex;
    justify-content: center;
    padding: 15px;
    background: #ffffff;
    box-shadow: 0px -4px 14px -3px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 15px 0px;
    position: relative;

    .responseInputContainer {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        position: relative;
        width: 85%;
    }

    input.responseInput {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 300;
        font-size: 20px;
        line-height: 14px;
        text-indent: 20px;
        color: $greyer;
        background: #ffffff;
        border: none;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 9px 16px 9px 10px;
        width: 100%;
        height: 30%;
        margin-right: 11px;
    }

    button.responseMessageFormButtton {
        background: #5685cc;
        border-radius: 50%;
        width: 52px;
        height: 50px;
        margin: auto;
        margin-right: 61px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
}

.actionIcons {
    display: flex;
    width: 100%;
    margin-left: 30px;
    align-items: center;

    svg {
        margin-right: 20px;
    }
}

.loadingSkeletor {
    width: 100%;
    height: 64px;
    display: block;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
        ),
        lightgray;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
    margin-bottom: 1em;

    @keyframes shine {
        to {
            background-position: 100% 0, /* move highlight to right */ 0 0;
        }
    }
}

.teamContent {
  color: #000000;
  display: flex;
  flex-flow: column;

  .teamHeader {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;

    h1.title {
      margin-bottom: 1em;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }

    h1.headerAction {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      cursor: pointer;
      color: #718ae2;
      text-align: right;
    }

    .editLabel:hover:before {
      content: "Team options";
      position: absolute;
      display: block;
      color: white;
      font-family: "Montserrat";
      padding: 3px 10px;
      border-radius: 5px;
      background-color: rgb(0, 0, 0, 0.6);
      margin-top: -37px;
      margin-left: -50px;
    }
  }

  .memberSection {
    font-family: "Montserrat";
    font-style: normal;
    color: #000000;
    margin-bottom: 3.5em;
    width: 85%;
    h2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }

    span {
      font-weight: 300;
      font-size: 16px;
      line-height: 17px;
    }
  }

  .saveTeamButton {
    align-self: flex-end;
    height: 3em;
    width: fit-content;
    padding: 0 3em;
    justify-content: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    background: #373d83;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;
  }

  .saveTeamButton:hover{
    background: #454b9f !important;
  }

  .editMenu {
    display: flex;
    flex-flow: column;
    justify-items: center;
    align-items: center;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;

    span {
      border-radius: 50%;
      background: #000000;
      width: 6px;
      height: 6px;
    }

    span:not(:last-child) {
      margin-bottom: 1px;
    }
  }
}

.blankAvatar {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background: #c4c4c4;
  display: inline-block;
  margin-right: 1em;
}

.member {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: center;
  align-items: center;
  margin: 1em 0;

  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
  }
}

/* Colors */

$blue: #718ae2;
$grey: #bababa;
$black: #2e2e2e;
$blacker: #565656;
$greyer: #707070;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap");

/* Mixins */

@mixin font-style {
    font-family: Roboto;
    font-style: normal;
}

@mixin container-shadow {
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}

@mixin flex-and-center {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

@mixin flex-and-space-between {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}

@mixin header-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

/* Chat Widgets Style */

.widgetToggler {
    background: $blue;
    border-radius: 50%;
    width: 51px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 1vw 60vw auto;
    float: right;
    margin-right: 22vw;
    margin-top: 2vw;
}

.chatWidgetContainer {
    @include font-style;
    @include container-shadow;
    height: 764px;
    width: 428px;
    background: #ffffff;
    margin: auto;
    position: relative;
    z-index: 1;

    .chatWidgetHeader {
        @include flex-and-space-between;
        height: 70px;
        background: linear-gradient(92.02deg, #718ae2 7.16%, #5e75c4 82.62%);
        border-radius: 30px 30px 0px 0px;

        // Header padding
        padding-left: 29px;
        padding-right: 29px;
        padding-top: 2em;
        padding-bottom: 3.6em;

        .headerItemGroup {
            display: flex;
            align-items: center;
            .chatMenu {
                display: flex;
                flex-flow: column;
                margin-right: 19px;
                svg:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
        .logoContainer {
            background: white;
            border-radius: 50%;
            padding: 0.5em;
            margin-right: 19px;
            svg {
                display: block;
            }
        }

        .chatHeaderText {
            @include header-text;
            color: #ffffff;
        }

        .headerControls {
            display: flex;
            align-items: center;
            svg:not(:last-child) {
                margin-right: 19px;
            }
        }
    }

    .chatWidgetContent {
        display: flex;
        flex-flow: column;
        align-items: center;
        background: #ffffff;
        border-radius: 30px 0px 0px 0px;
        position: relative;
        top: -30px;
        padding-top: 3vw;
        height: 540px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-bottom: 3em;

        &::-webkit-scrollbar {
            background-color: #fff;
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #babac0;
            border-radius: 16px;
            border: 4px solid #fff;
            background: #c4c4c4;
            border-radius: 50px;
        }

        .pageFlipImage {
            position: absolute;
            bottom: 1px;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 0;
        }

        .widgetMsgWrapper {
            display: flex;
            align-items: center;
            padding-left: 15px;
            flex-wrap: wrap;
            margin-bottom: 2.3vh;
            .logoContainerBlack {
                background: $blacker;
                border-radius: 50%;
                padding: 0.5em;
                margin-right: 19px;
                align-self: flex-end;
                position: relative;
                left: 20px;
                top: -10px;
                svg {
                    display: block;
                    path {
                        fill: white;
                    }
                }
            }

            .avatar {
                left: 0;
                background: #718ae2;
                top: -15px;
                border-radius: 50%;
                padding: 0.5em;
                margin-right: 19px;
                align-self: flex-end;
                position: relative;
                // height: 16px;
                // width: 16px;

                height: 35px;
                width: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .widgetQuickRepliesContainer {
                margin-left: 17%;
                margin-top: 8px;
                z-index: 1;
                .widgetQuickReply {
                    border: 1px solid $blue;
                    box-sizing: border-box;
                    border-radius: 15px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: $blue;
                    padding: 6px;
                    margin-right: 5px;
                    padding-right: 16px;
                    padding-left: 16px;
                    transition: 0.7s;
                    &:hover {
                        color: #fff;
                        background: $blue;
                    }
                }
            }
        }

        .msgWrapperRight {
            align-self: flex-end;
            margin-right: 23px;
        }

        .widgetMessageBody {
            max-width: 65%;
            margin: 1.4vh;
            text-align: center;
            padding: 10px;
            position: relative;
            .widgetUsername {
                position: absolute;
                top: -15px;
                font-weight: 300;
                font-size: 10px;
                line-height: 12px;
                color: $greyer;
            }
        }

        .responseMsg {
            background: #f1f1f1;
            border-radius: 15px 15px 15px 0px;
            align-self: flex-start;
            .text {
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                color: #0a0a0a;
                text-align: left;
            }
        }

        .requestMsg {
            background: #2e2e2e;
            border-radius: 15px 15px 0px 15px;

            align-self: flex-end;
            .textTyping {
                font-weight: 900;
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
            }

            .text {
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
            }
        }
    }

    .chatWidgetFooter {
        @include flex-and-center;
        flex-flow: column;
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
        color: $grey;
        width: 100%;

        p {
            padding-top: 1em;
        }

        .widgetForm {
            display: flex;
            justify-content: center;
            width: 90%;

            .widgetControlsSend {
                background: #718ae2;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #ffffff;
                border-radius: 15px;
                padding: 10px 20px 10px 20px;
            }

            .widgetControlsInput {
                font-weight: 300;
                font-size: 12px;
                line-height: 14px;
                color: $greyer;
                background: #ffffff;
                border: 1px solid #c1c1c1;
                box-sizing: border-box;
                border-radius: 15px;
                padding: 9px 16px 9px 10px;
                width: 100%;
                margin-right: 11px;
            }
        }
    }
}

/* Animation classes */

@mixin fade-in($waitTime) {
    animation: wait #{$waitTime}, fade-in 800ms #{$waitTime};
}

.fadeOut {
    animation: fadeOut ease 0.35s forwards;
    -webkit-animation: fadeOut ease 0.35s forwards;
    -moz-animation: fadeOut ease 0.35s forwards;
    -o-animation: fadeOut ease 0.35s forwards;
    -ms-animation: fadeOut ease 0.35s forwards;
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeIn {
    animation: fadeIn ease 0.7s forwards;
    -webkit-animation: fadeIn ease 0.7s forwards;
    -moz-animation: fadeIn ease 0.35s forwards;
    -o-animation: fadeIn ease 0.35s forwards;
    -ms-animation: fadeIn ease 0.35s forwards;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.transformTranslateQuickReplies {
    @include fade-in("1000ms");
    transition: 1s;
}

.tagModal {
    padding-top: 2em;
    position: absolute;
    z-index: 2;
    top: 5em;
    right: 3em;
    width: 21.9375em;
    height: 14.25em;
    background: #e8eef4;
    border-radius: 15px 0px 15px 15px;
    filter: drop-shadow(3px 5px 17px rgba(0, 0, 0, 0.15));

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    .tagInput {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: rgba(55, 83, 111, 0.4);

        input {
            padding-top: 10px;
            padding-right: 39px;
            padding-left: 39px;
            padding-bottom: 10px;
            background: #ffffff;
            box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.56);
            border-radius: 40px;
        }
    }

    .tagList {
        ul {
            list-style: none;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 0;

            li {
                font-family: Mukta;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                color: #373d83;
                span {
                    color: #ff0c0c;
                    margin-right: 2px;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }

        font-family: Mukta;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        display: flex;
        align-items: center;
        text-align: center;
        flex-flow: row;
    }

    .tagSubmitButton {
        color: #ffffff;
        width: 11.5625em;
        height: 2.5625em;
        background: #373d83;
        box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
        border-radius: 40px;
    }
}

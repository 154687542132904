.navigationContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 3em;
  margin-bottom: 1.625em;
  margin-right: 5.4375em;
  margin-left: 7em;

  .withNoticeCounter {
    margin-left: 1em;

    &::before {
      color: white;
      content: "5";
      background: #f05353;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      padding: 10px;
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      top: -3px;
      left: -18px;
    }
  }

  .headerLogo {
    // margin-right: 124px;
    // position: relative;
    // display: flex;
    position: relative;
    /* display: flex; */
    width: 146px;
    height: 33px;
    display: flex;
    align-self: center;

    .logoText {
      margin-top: 65px;
      margin-left: 7px;
    }

    .logoCube1 {
      position: absolute;
      width: 46px;
      height: 46px;
      left: 0px;
      top: 0px;
      background: rgba(44, 83, 245, 0.44);
      border-radius: 9px;
    }

    .logoCube2 {
      position: absolute;
      width: 46px;
      height: 46px;
      left: 7px;
      top: 7px;
      background: rgba(44, 83, 245, 0.44);
      border-radius: 9px;
    }

    .logoCube3 {
      position: absolute;
      width: 46px;
      height: 46px;
      left: 14px;
      top: 14px;
      background: rgba(44, 83, 245, 0.44);
      border-radius: 9px;
    }
  }

  ul {
    display: flex;
    align-items: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #000000;

    display: flex;
    flex-flow: row;
    list-style: none;

    li:last-child {
      margin-right: 0;
    }

    li {
      margin-right: 2.75em;
      z-index: 1;
      position: relative;

      display: flex;
      flex-flow: row;
      align-items: center;

      a {
        text-decoration: none;
        color: inherit;
      }

      a[aria-current="page"] {
        color: #59cdbf;
      }

      .accountAvatarContainer {
        background: #718ae2;
        border-radius: 50%;
        display: flex;
        padding: 6px;
        justify-content: center;
        align-items: center;
      }

      .accountBubble {
        width: 18.1875em;
        height: 3.3125em;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 9px;
        padding-right: 21px;
        background: #ffffff;
        box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(128 143 157 / 48%);
        border-radius: 40px;

        span {
          font-family: Roboto;
          font-style: normal;
          font-size: 16px;
          line-height: 19px;
          color: #828282;
          font-weight: 300;
        }
      }

      // &:nth-child(2) {
      //
      // }

      // &:nth-child(2)::before {
      //   color: white;
      //   content: "5";
      //   background: #f05353;
      //   margin-right: 5px;
      //   border-radius: 50%;
      //   width: 16px;
      //   height: 16px;
      //   padding: 5px;
      //   display: flex;
      //   position: absolute;
      //   justify-content: center;
      //   align-items: center;
      //   top: -5px;
      //   left: -32px;
      // }
    }
  }

  .logoutIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 51px;
    height: 51px;
    background: #e9eff5;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(128, 143, 157, 0.48);
    border-radius: 40px;
  }
}

// Background Colors

// Dark
$dark-blue-bg: #3d4485;

// Light
$light-white-bg: #fafafc;

// Global Colors
$white: #ffffff;
$dark: #000000;
$dark-grey: #8a8a8a;
$primary-blue: #373d83;
$light-blue: #718ae2;
$primary-green: #59cdbf;
$primary-red: #f35d5e;
$hover-blue: #454b9f;
$hover-red: #f25d5e;

/*
.blue_buttons{
    background-color: $primary-blue;
}
.blue_buttons:hover{
    background-color: $hover-blue;
}

.red_buttons{
    background-color: $primary-red;
}
.red_buttons:hover{
    background-color: $hover-red;
}
*/
.settingsContainer {
  display: flex;
  justify-content: center;

  // This is just an example how to isolate styles for a certain component
  // However, global styles should be defined inside src/styles/ directory for reusability
  // for example - fonts, box shadows, backgrounds - styles that should be consistent throughout the whole application

  .settingsBody {
    padding: 2em;
    min-width: 90%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 28px;
    color: rgba(55, 83, 111, 0.4);
    z-index: 1;
    position: relative;

    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 49px;
      color: #000;
      margin-bottom: 1.5em;
      width: 100%;
    }
    h2{
      margin-bottom: 20px;
    }

    .settingsLayout {
      display: flex;
      flex-flow: row;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 5em;
    }
  }
}

.drawerContainer {
  min-width: 200px;
  border-right: 2px solid #878bbc;
  margin-right: 3em;
  padding-right: 3em;
  padding-top: 1.5em;

  &::before {
    content: "Settings";
    position: absolute;
    top: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 49px;
    color: #000;
  }
}

.settingsContent {
  box-sizing: border-box;
  position: relative;
  /*add*/
  background: #ffffff;
  box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
  border-radius: 15px;
  max-width: 900px;
  width: 70%;
  padding: 3em;
}

.settingsTabButton {
  padding-left: 9px;
  padding-right: 21px;
  height: 3em;
  min-width: 215px;
  margin-bottom: 1em;
  background: #e5ecf5;
  box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.38);
  border-radius: 40px;

  //   font-family: "Montserrat";
  //   font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: rgba(55, 76, 131, 0.7);
}

.activeTab {
  background: #373d83;
  color: #ffffff;
  
}

// Should be in /src/styles/base/_reset.scss
ol,
ul {
  list-style: none;
  padding-left: 0;
}


@media only screen and (max-width:1366px) {
  .drawerContainer{
    min-width: 100%;
    border: none;
    margin: 0 auto;
    padding: 0px;


    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li{
        padding: 2px 10px;
       
      }
    }
  }.settingsContent {
    width: 90%;
    padding: 5em;
    margin-top: 50px;
  }
}
@media only screen and (max-width:1000px) {
  .settingsContent {
    width: 100%;
    padding: 2em;
  }
}

@media only screen and (max-width:768px) {
  .drawerContainer button{
        min-width: 150px;
        font-size: 14px;
        height: 3em;
      }
    .settingsContent {
        width: 100%;
        padding: 2em;
      }

    }
  
  
.overviewContainer {
  display: flex;
  justify-content: center;
  flex-flow: column;
  // This is just an example how to isolate styles for a certain component
  // However, global styles should be defined inside src/styles/ directory for reusability
  // for example - fonts, box shadows, backgrounds - styles that should be consistent throughout the whole application
  h1 {
    text-align: left;
    display: block;
    width: 100%;
    padding-left: 3em;
    margin-bottom: 1em;

    font-family: "Mukta";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 70px;

    color: #000000;
  }

  .overviewTable {
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
    border-radius: 15px;
    height: 500px;
    width: 80%;
    z-index: 1;
    position: relative;
    margin-top: 2em;
    margin: 2em auto;
  }

  .statisticsVisualization {
    display: flex;
    flex-flow: row;
    justify-content: center;
    .overviewBody {
      max-height: 500px;
      background: #ffffff;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
      border-radius: 15px;
      padding: 2em;
      width: 55%;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 28px;
      color: rgba(55, 83, 111, 0.4);
      z-index: 1;
      position: relative;

      h2 {
        top: 3em;
        left: 3em;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        display: inline-block;
      }
    }

    .overviewPie {
      width: 25%;
      height: 500px;
      padding: 2em;
      margin-right: 2em;
      background: #ffffff;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 25px rgba(138, 139, 139, 0.48);
      border-radius: 15px;
      z-index: 1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        position: absolute;
        top: 1.6em;
        left: 3em;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        display: inline-block;
      }

      .pieRing {
        background: #fbfbfb;
        box-shadow: 6px 10px 13px #e5ecf6, -10px -9px 13px #ffffff;
        border-radius: 50%;
        width: 255px;
        height: 255px;
      }
    }
  }
}

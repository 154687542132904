.advFit{
  position: relative;
  padding: 0px !important;

}
.textCounter {
  position: relative;
  span {
    position: absolute;
  }
  .counter {
    position: absolute;
    right: 25px;
    bottom: 30px;
    width: 20px;
    height: 20px;
    color: rgb(175, 175, 175);
  }
}

.webWidgetsContent {
  color: #000000;
  display: flex;
  flex-direction: column;
  h1.title {
    margin-bottom: 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .widgetHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 1.5em;
    }

    button.settingsButton {
      align-self: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #718ae2;
      cursor: pointer;
    }
  }

  .widgetControlSection {
    margin-bottom: 3em;
    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #000000;

      b {
        font-style: italic;
        font-weight: bold;
      }
    }
  }

  .widgetToggle {
    button {
      width: 64px;
      height: 41px;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #ffffff;
    }

    .blue_hover:hover {
      background: #454ca0;
    }
    button:nth-child(1) {
      margin-right: 0.5em;
    }

    .on {
      background: #373d83;
    }

    .off {
      background: #e0e0e0;
    }
  }

  .addNewWidget {
    background: rgba(251, 252, 252, 0.9);
    border-radius: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    border: 2px dashed #9b9b9b;
    box-sizing: border-box;
    border-radius: 40px;
    height: 3em;
    width: 100%;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    margin-bottom: 3em;
  }

  .widgetSettingGroup {
    padding: 1em 0;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;

    button.changeStyle {
      background: #718ae2;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
      border-radius: 40px;
      height: 2.5em;
      width: 250px;
      padding: 0 5em;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      text-align: center;
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: rgba(55, 76, 131, 0.7);
      min-width: 250px;
    }

    input {
      background: #ffffff;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
      border-radius: 40px;
      height: 3em;
      min-width: 250px;
      -webkit-appearance: none;
      border: none;
      text-indent: 20px;
    }
  }

  .saveWidgetsButton {
    height: 3em;
    width: fit-content;
    padding: 0 3em;
    justify-content: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    background: #373d83;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;

    button {
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
      border-radius: 40px;
      cursor: pointer;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      padding: 0.6em 2em;
      color: #ffffff;
      height: 3em;
      max-width: 200px;
      
    }
  }

}

.AdvWidgetsContent {
  box-sizing: border-box;
  position: absolute;
  background: #fff;
  width: 100%;
  box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
  padding: 2em 2em;
  border-radius: 10px;
  z-index: 99;
  height: 100%;
  top: 0%;
  right: 0%;
  
  margin: 0 auto;

  .advWidgetsInner{
    position: relative;
    overflow-y: scroll;
    max-height: 80%;
  }

  .titleRow {
    max-height: 20%;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .domainList {
    display: block;
  }

  .domainList div {
    display: flex;
    flex-direction: row;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.4);

    .closeModal {
      color: #f35d5d;
      transform: rotate(-44.04deg);
      margin-left: 0.9em;
      cursor: pointer;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
    }
  }
  .triggerCollapse {
    input {
      border: 1px solid #D7D7D7;
      padding: 0.8em 1em;
      border-radius: 15px;
      margin-left: 10px;
    }

    span {
      display: flex;
      padding: 10px;
    }

    .textBoxMessage textarea,
    .textBoxMessage input {
      background: #ffffff;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
      border-radius: 10px;
      -webkit-appearance: none;
      border: none;
      padding: 10px;
      margin-left: 20px;
      resize: none;
    }

    .textName input {
      background: #ffffff;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
      border-radius: 40px;
      height: 23em;
      min-width: 235px;
      -webkit-appearance: none;
      border: none;
      text-indent: 20px;
    }

    p.question {
      background-color: #b9b9b9;
      font-size: 12px;
      color: #fff;
      font-family: Montserrat;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0px 0px 10px;
      cursor: pointer;

      &:hover:before {
        content: "Lorem ipsum";
        position: absolute;
        display: block;
        color: white;
        font-family: "Montserrat";
        padding: 3px 10px;
        border-radius: 5px;
        background-color: rgb(0, 0, 0, 0.6);
        margin-top: -57px;
      }
    }
  }
  .saveWidgetsButton {
    margin-top: 20px;
    color: #fff;
  }
}

.closeModal {
  color: #f35d5d;
  transform: rotate(-44.04deg);
  margin-left: 0.9em;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 30px;
  margin-top: 10px;
  z-index: 99;
}

label {
  min-width: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #435ea1;
}


@media only screen and (min-width:768px) {
  .saveWidgetsButton  {
    align-self: flex-end;
  } 
}

@media only screen and (max-width: 1366px) {
  .saveWidgetsButton  {
    align-self: center;
  }
  .AdvWidgetsContent{
    width: 91%;
    top: 23%;
    right: 5%;
  }
}
@media only screen and (max-width: 1006px) {
  .saveWidgetsButton  {
    align-self: center;
  }
  .AdvWidgetsContent{
    width: 91%;
    top: 27%;
  }
}

@media only screen and (max-width: 768px) {
  .saveWidgetsButton  {
    align-self: center;
  }
  .AdvWidgetsContent{
    width: 91%;
  }
}

@media only screen and (max-width: 428px) {
  .widgetSettingGroup {
    justify-content: center;

    span {
      text-align: center;
    }
  }
}
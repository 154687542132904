.FlowHeader {
    position: relative; 
    border-radius: 20px;
    margin: 5vh 10vh;
    margin-top: 2.5vh;
    display: grid;
    grid-template-rows: auto 1fr;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 28px;
    color: rgba(55, 83, 111, 0.4);
    z-index: 3;
    padding: 20px;
}
.FlowHeader h2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 49px;
    color: #000000;
}
.FlowHeader .searchForm{
    display:flex;
}
.FlowHeader .searchForm input{
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius:  40px 0px 0px 40px ;
    height: 3em;
    min-width: 250px;
    -webkit-appearance: none;
    border: none;
    text-indent: 20px;
}
.FlowHeader .searchForm button img{
    max-width: 15px;
    cursor: pointer;
    margin-top: 5px;
}
.FlowHeader .searchForm button{
    background: #ffffff;
    box-shadow: -4px -2px 16px #ffffff, 5px 2px 10px rgba(136, 165, 191, 0.48);
    border-radius: 0px 40px 40px 0px ;
    height: 3em;
    min-width: 40px;
    -webkit-appearance: none;
    border: none;
    margin-left: -2px;
    cursor: pointer;
}
.FlowHeader .SearchBox a{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #000000;
    padding-left: 20px;
}
.FlowHeader .SearchBox{
    display: flex;
    padding-top: 20px;
}
.flows-container {
    position: relative;
    background: #fbfbfc;
    box-shadow: -4px -2px 16px rgba(255, 255, 255, 0.71), 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 20px;
    margin: 10vh;
    margin-top: 2.5vh;
    display: grid;
    grid-template-rows: auto 1fr;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 28px;
    color: rgba(55, 83, 111, 0.4);
    min-height: max-content;
    z-index: 3;
    padding: 20px;
}

table{
    border-collapse: separate;
    border-spacing: 0px 12px ;
}


table tr{
    border-radius: 40px;
}
tr.flowItems {
    background: #e0e8f2;
    /* shadow white */
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;
    margin-bottom: 10px;
    padding: 14px;
    width: 100%;
}

td:first-child,
th:first-child {
  border-radius: 40px 0 0 40px;
}
td:last-child,
th:last-child {
  border-radius: 0 50px 40px 0;
}
.folder-container {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 2vw;
    z-index: auto;
}

.folder-button {
    margin-right: 14px;
}

.flow-container {
    position: relative;
    width: 100%;
    z-index: auto;
    min-height: max-content;
}

.folder-item {
    display: block;
    background: #ffffff;
    /* shadow white 
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);*/
    border-radius: 40px;
    margin-bottom: 10px;
    padding: 10px;
    min-width: 120px;
}

.flow-item {
    display: block;
    /*background: #e0e8f2;*/
    /* shadow white 
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);*/
    border-radius: 40px;
    border-radius: 40px;
    margin-bottom: 10px;
    padding: 14px;
    padding-left: 30px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.flow-item-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #696c8f;
}

.folder-separator {
    color: rgb(157 169 206 / 38%);
    font-weight: bolder;
    font-size: 2rem;
    padding: 1vh;
    padding-bottom: 2vh;
}

.folder-path {
    background: #353886;
    border-radius: 40px;
    color: #615e5e;
    padding-left: 14px;
    padding-right: 14px;
    color: #ffffff;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
}

.foldersContainer {
    position: relative;
    background: #fbfbfc;
    box-shadow: -4px -2px 16px rgb(255 255 255 / 71%), 4px 2px 16px rgb(136 165 191 / 48%);
    border-radius: 20px;
    margin: 10vh;
    margin-bottom: 6vh;
    margin-top: 2.5vh;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 28px;
    color: rgba(55, 83, 111, 0.4);
    z-index: 3;
    padding: 20px;
    display: flex;
    flex-flow: row;
}

.folderTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #696c8f;
    margin-left: 10px;
}

.folderDiv {
    background: #e0e8f2;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 48%);
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin-right: 15px;
}

.foldersHeaderRow {
    display: flex;
    flex-direction: row;
}

.addNewFolder {
    border: 2px dashed rgba(57, 62, 133, 0.5);
    box-sizing: border-box;
    border-radius: 40px;
    width: 140px;
    display: flex;
    justify-content: center;
}

.addNewFolder span {
    display: flex;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(57, 62, 133, 0.5);
}
.addNewWidget {
    margin: 20px 0px;
    background: rgba(251, 252, 252, 0.9);
    border-radius: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    border: 2px dashed #9b9b9b;
    box-sizing: border-box;
    border-radius: 40px;
    height: 3em;
    width: 100%;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    margin-bottom: 3em;
  }

@import "../../../../styles/utils/_colors.scss";
@import "../../../../styles/utils/_variables.scss";
@import "../../../../styles/base/_reset.scss";
@import "../../../../styles/base/_typography.scss";


.billingContent {
  color: $dark;
  position: relative;

  p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  h1.title {
    margin-bottom: 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
  button {
    background: $primary-blue;
    box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgba(136, 165, 191, 0.48);
    border-radius: 40px;
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    height: 45px;
    width: 200px;
    color: $white;
  }
  button:hover {
    background: $hover-blue;
  }
  .statusContent {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .status {
    height: 10px;
    width: 10px;
    background-color: $primary-red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 1em;
  }

  .addNewModal {
    opacity: 0;
    animation: slide 0.5s forwards 0.3s;
    position: absolute;
    width: 60%;
    top: 10%;
    height: 100%;
    padding: 4em;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    background: $white;
    box-shadow: -4px -2px 16px #fff, 4px 2px 25px rgb(138 139 139 / 48%);
    border-radius: 15px;

    margin-left: -1em;

    .closeModal {
      color: $primary-red;
      transform: rotate(-44.04deg);
      margin-left: 0.9em;
      cursor: pointer;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      position: absolute;
      line-height: 30px;
      top: 6%;
      right: 6%;
    }

    .payButton {
      background: $primary-blue;
      font-size: 16px;
      margin: 2em 0;
      align-self: center;

      padding: 0.5em 3em;
      box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 38%);
      border-radius: 40px;
      font-weight: 400;
      line-height: 20px;
      width: 120px;

      &:disabled {
        background: #e5ecf5;
        pointer-events: none;
      }
    }

    .cardForm {
      width: 90%;
      display: flex;
      flex-flow: column;
      margin-top: 3em;

      .formRow {
        display: flex;

        label {
          width: 50%;

          &:nth-child(1) {
            margin-right: 10px;
          }
        }
      }

      label {
        margin-top: 0.7em;

        span {
          padding-left: 1em;
        }

        .cardNumberElement {
          background: $white;
          box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 48%);
          border-radius: 40px;
          height: 2.6em;
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding-left: 1em;
        }

        .cardExpiryElement {
          background: $white;
          box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 48%);
          border-radius: 40px;
          height: 2.6em;
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding-left: 1em;
        }

        .cardCvcElement {
          background: $white;
          box-shadow: -4px -2px 16px #ffffff, 4px 2px 16px rgb(136 165 191 / 48%);
          border-radius: 40px;
          height: 2.6em;
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding-left: 1em;
        }
      }
    }
  }

  @keyframes slide {
    0% {
      left: 80%;
      opacity: 0;
    }
    100% {
      left: 15%;
      opacity: 1;
    }
  }

  // This makes text blurry after translating
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(20%);
    }
  }
}

.devMessages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  background-color: #19334a;
  color: #00d924;
  padding: 10px;
  margin: 10px 0;

  font-size: 0.7em;

  position: absolute;
  bottom: -35%;
  box-shadow: -4px -2px 16px #fff, 4px 2px 25px rgb(138 139 139 / 48%);
  border-radius: 15px;
  padding: 2em;
}

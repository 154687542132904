@import "../../../../styles/utils/_colors.scss";
@import "../../../../styles/utils/_variables.scss";
@import "../../../../styles/base/_reset.scss";
@import "../../../../styles/base/_typography.scss";

.snippetsContent {
  color: $dark;

  h1.title {
    margin-bottom: 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .editMenu {
    display: flex;
    flex-flow: column;
    justify-items: center;
    align-items: center;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;

    width: fit-content;
    padding-left: 10px;
  }

  span {
    border-radius: 50%;
    background: $primary-blue;
    width: 6px;
    height: 6px;
  }

  span:not(:last-child) {
    margin-bottom: 1px;
  }
}

table {
  border-collapse: separate;
  border-spacing: 20px;
  width: 100%;

  input {
    border: 1px solid #D7D7D7 ;
    padding: 0.8em 1em;
    border-radius: 15px;
  }
  tbody{
    padding-top: 20px;
  } 
  
  thead tr {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    
  }
  tr {
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    
  }


  
  th p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgb(121, 121, 121);
  }

  .moreButton span {
    background-color: $primary-blue;
    padding: 2px 8px;
    color: $white;
    border-radius: 100%;
    font-weight: 400;
  }
  .moreButton {
    cursor: pointer;
  }
}


@media only screen and (max-width:768px) {
  table {
    border-collapse: separate;
    border-spacing: 10px;
    width: 100%;
      p{
        font-size: 12px;
      }
      input {
        max-width: 100px;
      }
    } 
  }